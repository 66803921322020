<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Surat Tugas </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateSurattugas">
              <CRow>
              <CCol sm="9">
                <CInput
                  v-model="no_surat_tugas"
                  
                  label="Nomor Surat Tugas"
                  placeholder="Input Nomor Surat Tugas"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_surat_tugas"
                  type="date"
                  label="Tanggal Surat Tugas"
                  placeholder="Input Tanggal Surat Tugas"
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="uraian_surat_tugas"
                  label="Uraian Surat Tugas"
                  placeholder="Input Uraian Surat Tugas"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_pelaksanaan"
                  type="date"
                  label="Tanggal pelaksanaan"
                  placeholder="Input Tanggal pelaksanaan"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="jumlah_hari_pelaksanaan"
                  type="number"
                  label="Jumlah Hari Pelaksanaan"
                  placeholder="Input Jumlah Hari"
                />
              </CCol>              
              <CCol sm="6">
                <CInput
                  v-model="dasar_surat_tugas"
                  label="Dasar Surat Tugas"
                  placeholder="Input Dasar Surat Tugas"
                />
              </CCol>
              <CCol sm="12">
                <div class="form-group">
                <label>Nama Pelaksana</label>
                <multiselect v-model="nama_pelaksana" :options="pegawais" :multiple="true" :taggable="true"></multiselect>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Pejabat TTD</label>
                  <select v-model="pejabat_ttd" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="o in pejabats" :value="o.nama_pegawai">{{o.nama_pegawai}}</option>
                  </select>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Jenis Tujuan Dinas</label>
                  <select v-model="jenis_tujuan" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Dalam Provinsi</option>
                    <option value="2">Luar Provinsi</option>
                    <option value="3">Luar Negeri</option>
                  </select>
                </div>
              </CCol>  
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Akun Sakti</label>
                  <select class="form-control" v-model="nama_upt">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="r in upts" :value="r.id_upt">{{r.nama_upt}} </option>
                  </select>
                </div>
              </CCol>                -->
            </CRow>      
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/surattugas">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import InputTag from 'vue-input-tag'
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    InputTag ,
    Multiselect 
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nama_upt : "",
      no_surat_tugas : "",
      tgl_surat_tugas : "",
      uraian_surat_tugas : "",
      tgl_pelaksanaan : "",
      jumlah_hari_pelaksanaan : 0,
      nama_pelaksana : "",
      dasar_surat_tugas : "",
      pejabat_ttd : "",
      jenis_tujuan : "",
      pegawais : [],
      pejabats : [],
      selected: [], // Must be an array reference!
      upts: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showSurattugas();
  },

  methods: {
    
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
        .then((response) => {
              
              const datas = response.data;
              response.data.forEach(obj=> this.pegawais.push(obj.nama_pegawai));
            });

      axios.get(process.env.VUE_APP_BASE_URL+"pegawais/pejabat")
        .then((response) => {
              
              const datas = response.data;
              this.pejabats = response.data;
              // response.data.forEach(obj=> this.pejabats.push(obj.nama_pegawai));
            });

      axios.get(process.env.VUE_APP_BASE_URL+"upts")
        .then((response) => {
              this.upts=response.data;
            });
    },

    updateSurattugas: function(event){

      const surattugas = { 
                          id_upt: this.nama_upt, 
                          no_surat_tugas: this.no_surat_tugas, 
                          tgl_surat_tugas: this.tgl_surat_tugas, 
                          uraian_surat_tugas: this.uraian_surat_tugas,
                          tgl_pelaksanaan: this.tgl_pelaksanaan,
                          jumlah_hari_pelaksanaan: this.jumlah_hari_pelaksanaan,
                          nama_pelaksana: this.nama_pelaksana,
                          dasar_surat_tugas: this.dasar_surat_tugas,
                          pejabat_ttd: this.pejabat_ttd,
                          jenis_tujuan: this.jenis_tujuan,
                          // notif: this.notif,
                          id_surat_tugas: this.$route.params.id_surat_tugas
                          };

      // alert(JSON.stringify(pekerjaan));
      axios.put(process.env.VUE_APP_BASE_URL+"surattugas/"+this.$route.params.id_surat_tugas, surattugas)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/surattugas');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showSurattugas: function(event){
    
      const surattugas = { 
                          nama_upt: this.nama_upt, 
                          no_surat_tugas: this.no_surat_tugas, 
                          tgl_surat_tugas: this.tgl_surat_tugas, 
                          uraian_surat_tugas: this.uraian_surat_tugas,
                          tgl_pelaksanaan: this.tgl_pelaksanaan,
                          jumlah_hari_pelaksanaan: this.jumlah_hari_pelaksanaan,
                          nama_pelaksana: this.nama_pelaksana,
                          dasar_surat_tugas: this.dasar_surat_tugas,
                          jenis_tujuan: this.jenis_tujuan,
                          // notif: this.notif,
                          pejabat_ttd: this.pejabat_ttd
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"surattugas/"+this.$route.params.id_surat_tugas)
        .then((response) => {            
              this.nama_upt=response.data.id_upt;
              this.no_surat_tugas=response.data.no_surat_tugas;
              this.tgl_surat_tugas=response.data.tgl_surat_tugas;
              this.uraian_surat_tugas=response.data.uraian_surat_tugas;
              this.tgl_pelaksanaan=response.data.tgl_pelaksanaan;
              this.jumlah_hari_pelaksanaan=response.data.jumlah_hari_pelaksanaan;
              this.dasar_surat_tugas=response.data.dasar_surat_tugas;
              this.nama_pelaksana=response.data.nama_pelaksana;
              this.pejabat_ttd=response.data.pejabat_ttd;
              // this.notif=response.data.notif;
              this.jenis_tujuan=response.data.jenis_tujuan;
        })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>